// USER
export const TOKEN = "VISCIO_ADMIN_TOKEN";
export const GET_USER = "GET_USER";
export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_LOADING = "UPDATE_ADMIN_LOADING";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_LOADING = "SEARCH_USERS_LOADING";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";
export const SEARCH_USERS_RELOAD = "SEARCH_USERS_RELOAD";
export const GET_COMPLETE_USERS = "GET_COMPLETE_USERS";

// TEMP
export const TEMP_ADMIN_VISCIO = "TEMP_ADMIN_VISCIO";

// ERRORS
export const GET_ERRORS = "GET_ERRORS";
export const GET_ERRORS_TEXT = "GET_ERRORS_TEXT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// ORDER
export const GET_ORDER = "GET_ORDER";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ORDER_LOADING = "GET_ORDER_LOADING";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";
export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_LOADING = "UPDATE_ORDER_LOADING";
export const ACCEPT_ORDER = "ACCEPT_ORDER";
export const GET_BIDS = "GET_BIDS";
export const GET_BIDS_LOADING = "GET_BIDS_LOADING";
export const GET_BIDS_FAIL = "GET_BIDS_FAIL";
export const GET_BIDS_PENDING = "GET_BIDS_PENDING";
export const GET_BIDS_PICKUP = "GET_BIDS_PICKUP";
export const GET_BIDS_COMPLETED = "GET_BIDS_COMPLETED";
export const GET_TRACK_ORDER = "GET_TRACK_ORDER";
export const GET_TRACK_ORDER_LOADING = "GET_TRACK_ORDER_LOADING";
export const GET_TRACK_ORDER_FAIL = "GET_TRACK_ORDER_FAIL";
export const GET_ALL_BIDS = "GET_ALL_BIDS";

// REFERRAL
export const GET_REFERRAL = "GET_REFERRAL";
export const GET_REFERRAL_LOADING = "GET_REFERRAL_LOADING";
export const GET_REFERRAL_FAIL = "GET_REFERRAL_FAIL";
export const ADD_REFERRAL = "ADD_REFERRAL";
export const ADD_REFERRAL_FAIL = "ADD_REFERRAL_FAIL";

// REWARD
export const GET_REWARD = "GET_REWARD";
export const GET_REWARD_LOADING = "GET_REWARD_LOADING";
export const GET_REWARD_FAIL = "GET_REWARD_FAIL";
export const ADD_REWARD = "ADD_REWARD";
export const ADD_REWARD_FAIL = "ADD_REWARD_FAIL";
export const UPDATE_REWARD = "UPDATE_REWARD";
export const UPDATE_REWARD_LOADING = "UPDATE_REWARD_LOADING";
export const DELETE_REWARD = "DELETE_REWARD";
export const UPDATE_REWARD_FAIL = "UPDATE_REWARD_FAIL";
export const DELETE_REWARD_FAIL = "DELETE_REWARD_FAIL";

// PRICE_CHECKER
export const GET_PRICE_CHECKER = "GET_PRICE_CHECKER";
export const GET_PRICE_CHECKER_LOADING = "GET_PRICE_CHECKER_LOADING";
export const GET_PRICE_CHECKER_FAIL = "GET_PRICE_CHECKER_FAIL";
export const ADD_PRICE_CHECKER = "ADD_PRICE_CHECKER";
export const ADD_PRICE_CHECKER_FAIL = "ADD_PRICE_CHECKER_FAIL";
export const UPDATE_PRICE_CHECKER = "UPDATE_PRICE_CHECKER";
export const UPDATE_PRICE_CHECKER_FAIL = "UPDATE_PRICE_CHECKER_FAIL";
export const DELETE_PRICE_CHECKER = "DELETE_PRICE_CHECKER";
export const DELETE_PRICE_CHECKER_FAIL = "DELETE_PRICE_CHECKER_FAIL";

export const SOCKET = "SOCKET";
export const SOCKET_USER = "SOCKET_USER";
export const RECONNECT = "RECONNECT";
export const RECONNECT_NOT = "RECONNECT_NOT";

//CHAT
export const GET_CHAT = "GET_CHAT";
export const GET_CHAT_LOADING = "GET_CHAT_LOADING";
export const GET_CHAT_FAIL = "GET_CHAT_FAIL";
export const ADD_CHAT = "ADD_CHAT";
export const ADD_CHAT_FAIL = "ADD_CHAT_FAIL";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const UPDATE_CHAT_FAIL = "UPDATE_CHAT_FAIL";

// DISPUTE
export const GET_DISPUTE = "GET_DISPUTE";
export const GET_ALL_DISPUTE = "GET_ALL_DISPUTE";
export const GET_DISPUTE_FAIL = "GET_DISPUTE_FAIL";
export const ADD_DISPUTE = "ADD_DISPUTE";
export const ADD_DISPUTE_FAIL = "ADD_DISPUTE_FAIL";
export const UPDATE_DISPUTE = "UPDATE_DISPUTE";
export const UPDATE_DISPUTE_FAIL = "UPDATE_DISPUTE_FAIL";

// FEEDBACK
export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";

// ADMIN
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";

// WALLET
export const GET_TOTAL_INCOME = "GET_TOTAL_INCOME";
export const GET_WITHDRAWAL_REQUEST = "GET_WITHDRAWAL_REQUEST";
export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_LOADING = "GET_WALLET_LOADING";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";
export const UPDATE_WALLET = "UPDATE_WALLET";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_FAIL = "SEND_FAIL";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";

export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const GET_BANK_ACCOUNT_FAIL = "GET_BANK_ACCOUNT_FAIL";
export const ADD_WITHDRAWAL_REQUEST = "ADD_WITHDRAWAL_REQUEST";
export const GET_ALL_BANKS = "GET_ALL_BANKS";
export const ADD_WALLET = "ADD_WALLET";
export const ADD_WALLET_FAIL = "ADD_WALLET_FAIL";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
